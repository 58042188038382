<template>
	<v-app>
		<router-view />
	</v-app>
</template>

<script>
export default {
	name: "App",
	mounted() {},
};
</script>

<style lang="scss">
.profile-img {
	border: 1px solid #f0f0f0;
}
</style>
