import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/app-check'
import { PROD_CONFIG, DEV_CONFIG } from './configs';

const isDev = window.location.hostname.includes('localhost') || window.location.hostname.includes('prod-mint.ch')
const firebaseConfig = isDev ? DEV_CONFIG : PROD_CONFIG

firebase.initializeApp(firebaseConfig)

const appCheck = firebase.appCheck()
const db = firebase.firestore()
const storage = firebase.storage()

appCheck.activate('6Le2QB8hAAAAAPzwqNrMVEElM2HRmU1zr9WrXMYY', true)

export { db, storage }