const PROD_CONFIG = {
    apiKey: "AIzaSyDgbDf6zZGkcRhWeeRHn9oe5CD3xpZ9GYY",
    authDomain: "tpi-mathieu.firebaseapp.com",
    projectId: "tpi-mathieu",
    storageBucket: "tpi-mathieu.appspot.com",
    messagingSenderId: "98602181633",
    appId: "1:98602181633:web:60275d41fb5499c5bb0c00"
}

const DEV_CONFIG = {
    apiKey: "AIzaSyDaYcMCCHUDgNruVSYk9G8jJ5YTE6YqLIo",
    authDomain: "mintable---dev.firebaseapp.com",
    projectId: "mintable---dev",
    storageBucket: "mintable---dev.appspot.com",
    messagingSenderId: "83112888562",
    appId: "1:83112888562:web:a2566914808a27d6a0286b"
}

export { PROD_CONFIG, DEV_CONFIG }