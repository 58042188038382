import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import NProgress from 'nprogress'
import { checkAuthState, checkEmailAuth } from '@/services/authentification'

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		redirect: { name: "Home" },
		component: () => import("@/layouts/FullLayout"),
		children: [
			{
				name: "Home",
				path: "home",
				component: () => import("@/views/Home"),
				children: [
					{
						name: "Directory",
						path: ":directory",
						component: () => import("@/views/Home"),
					}
				]
			},
			{
				path: "login",
				redirect: { name: "Login" },
			},
			{
				path: "register",
				redirect: { name: "Register" },
			}
		]
	},
	{
		path: "/table",
		redirect: { name: "Home" },
		component: () => import("@/layouts/FullLayout"),
		children: [
			{
				name: "Table",
				path: ":tableId",
				component: () => import("@/views/Table")
			},
		]
	},
	{
		name: "Invite",
		path: "/table/:tableId/:email",
		meta: { email: true },

	},
	{
		name: "Notification",
		path: "/table/:tableId/:rowId/:noteId/:email",
		meta: { email: true },
	},
	{
		path: "/authentication",
		redirect: { name: "Login" },
		component: () => import("@/layouts/BaseLayout"),
		children: [
			{
				name: "Login",
				path: "login",
				component: () => import("@/views/authentication/Login"),
				meta: { guest: true }
			},
			{
				name: "Register",
				path: "register",
				component: () => import("@/views/authentication/Register"),
				meta: { guest: true }
			},
		]
	},
	{
		path: "*",
		component: () => import("@/views/authentication/Error")
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo);
	},
	routes
})

router.beforeEach(async (to, from, next) => {
	if (to.meta.email) {
		await checkEmailAuth(to, from, next)
	} else {
		if (to.meta.guest && to.params.tableId) {
			next()
		} else {
			await checkAuthState(to, from, next)
		}
	}
})


router.afterEach(() => { NProgress.done(); });

export default router
